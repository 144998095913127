.react-chatbot-kit-chat-container {
  position: fixed;
  border-radius: 16px;
  width: 388px;
  height: 523px;
  bottom: 95px;
  right: 56px;
  box-shadow: 0px 20px 25px -3px rgba(55, 65, 81, 0.05),
    0px 10px 10px -2px rgba(55, 65, 81, 0.05);
}

.react-chatbot-kit-chat-bot-message {
  width: auto !important;
}

.react-chatbot-kit-chat-btn-send {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--Surface-Fill-Green, #bcdd33);
}

.react-chatbot-kit-chat-message-container{
    display: flex;
    width: 388px;
    height: 368px;
    flex-direction: column;
    overflow-y: auto;
    gap: 20px;
    margin-top: 20px;
}

.react-chatbot-kit-chat-btn-send-icon {
  display: flex;
  background-image: url('../../../../assets/images/arrow-right-emerald.svg') !important;
}

.react-chatbot-kit-chat-input {
  display: flex;
  height: 48px;
  padding: 12px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Supporting-Default-Border, #cbd6d3);
  background: var(--Surface-1, #fff);
}

.react-chatbot-kit-chat-input-form {
  display: flex;
  width: 388px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid var(--Supporting-Divider, #cbd6d3);
  background: #fff;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #7f8785; /* Light grey color */
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.react-chatbot-kit-chat-btn-send-icon path {
  display: none;
}

.react-chatbot-kit-user-chat-message-container{
    margin: 0;
    gap: 10px;
}

.react-chatbot-kit-chat-bot-message-container{
    margin: 0;
    gap:10px;
}