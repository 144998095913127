.hrd-otp-input {
  border-radius: 8px;
  border: 1px solid #cbd6d3;
  background-color: #ffffff;
  margin: 1px;
  justify-content: space-between;
  height: 72px;
  width: 72px !important;
  outline: none;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  font-family: 'Work Sans';
  color: #595959;
  caret-color: transparent;
}

.hrd-otp-input:focus {
  border: 2px solid #025f4c;
}

.hrd-otp-error {
  background-color: #fff2f2;
  border: 1px solid #ff8080;
}

@media (max-width: 550px) {
  .hrd-otp-input {
    height: 40px !important;
    width: 40px !important;
  }
}

.hrd-otp-container {
  justify-content: space-between;
}
