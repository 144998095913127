.employee_name_initials {
  text-align: center;
}

.employee_name_initials .sc-bdnxRM {
  display: inline-block;
  line-height: 35px;
}

.employee_comp_name {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  text-align: left;
}

.container {
  max-width: 1164px !important;
  margin: 0 auto;
}
.dataTable_inner {
  // padding: 10px;
  background: #ffffff;
  border-radius: 8px;
}

.table-row:hover {
  cursor: pointer;
  background-color: #d0e7e3;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #eaecf1 !important;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #eaecf1 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #959fbe;
  padding: 15px 0;
}
.table tbody td {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #3e4a59;
  padding: 15px 0;
}
.card-dashboard {
  background: #ffffff;
  border: 1px solid #d4e3eb;
  box-sizing: border-box;
  border-radius: 12px !important;
  padding: 20px;
}
.card-body {
  padding: 30px !important;
}
.card-dashboard .image-container {
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-dashboard .title {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #667985;
  margin-bottom: 21px;
  white-space: pre-wrap;
}
.card-dashboard .amount {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 14px;
  color: #3e4a59;
  margin-right: 15px;
  margin-top: 15px;
}
.card-container {
  margin-bottom: 15px;
}
.card-alignment {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.text-orange {
  color: #d58c38;
  // text-transform: uppercase;
  font-size: 12px;
  float: left;
}

.text-green {
  color: #18a294;
  // text-transform: uppercase;
  font-size: 12px;
  float: left;
}

.text-progress {
  color: #009cc3;
  font-size: 12px;
  float: left;
}

.faded_bg_btn {
  height: 100%;
  width: 8px;
  position: absolute;
  right: 0;
  top: 0;
  background: #f0f3f5bf;
  // box-shadow: -3px 1px 10px 3px rgba(240 243 245 / 75%);
  box-shadow: -3px 1px 10px 3px #f0f3f5bf;
}

.dataTable_controls header {
  padding: 0px !important;
}

.dataTable_controls .page-link {
  color: rgb(0, 32, 51);
}
.dataTable_controls .page-link[disabled] {
  color: rgb(160, 174, 192);
}

.policy_wrapper {
  margin-left: -1rem;
  margin-right: -1rem;
}

.dt_no_records {
  background: rgba(124, 185, 178, 0.1);
  border-radius: 20px;
  width: 65%;
  padding: 50px;
  margin: 50px 0px;
  text-align: center;
  align-self: center;
}

.dt_no_records label {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #3e4a59;
}
.dt_no_records span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #718096;
  display: block;
  margin-top: 20px;
}
